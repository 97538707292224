import { AiOutlineHome } from "react-icons/ai";
import { FaReact } from "react-icons/fa";
import { SiAirplayaudio } from "react-icons/si";

import { Box, Grid, Text } from "atoms";
import { ShowcaseCard } from "molecules";

const CARDS = [
  {
    Icon: <SiAirplayaudio/>,
    title: "Audio Visual Solutions",
    description: "Our aim is to provide best sound experience to our clients using best quality products from leading companies like JBL ,BOSE ,BOSCH ,SENNHEISER ,AUDIO TECHNICA etc.We are also providing Video Solutions, to give the best video experience to our clients using products from leading companies like SONY ,SAMSUNG ,OPTOMA ,EPSON ,PHILLIPS ,LG etc."
  },
  {
    Icon: <FaReact/>,
    title: "IT Solutions",
    description: "We are providing IT Solutions to our clients using best technology available in the market. We are providing solutions like Software Development, Web Development, Mobile App Development, Digital Marketing and more."
  },
  {
    Icon: <AiOutlineHome/>,
    title: "Home Automation",
    description: "“Home automation” refers to the automatic and electronic control of household features, activity, and appliances. In simple terms, it means you can easily control the utilities and features of your home via the Internet to make life more convenient and secure, and even spend less on household bills."   
  },  
]

export const HomeShowcase = ({
  title,
  description,
}) => {
  return (
    <Box      
      py={{ xs: "4rem", xm: "6rem"}}       
      px={{ xs: "4rem", xm: "6rem"}}      
    >
      <Box
        position="absolute"
        width="14.5rem"
        height="20.5rem"
        overflow="hidden"
        top="0"
        left="0"
        zIndex="0"
        backgroundImage="url('/images/dots.png')"
      />
      <Box
        position="absolute"
        width="14.5rem"
        height="20.5rem"
        overflow="hidden"
        bottom="0"
        right="0"
        zIndex="0"
        backgroundImage="url('/images/dots.png')"
      />
      <Text             
        fontSize="4rem"
        fontWeight="700"
        color="dark.500"        
        maxWidth={{xs: "100%", xm: "48rem", lg: "63rem"}}
        mx="auto"
        textAlign="center"
      >
        {title}
      </Text>  
      <Text
        fontSize="1.6rem"
        fontWeight="300"
        color="grey.300"
        mt="1rem"
        lineHeight="2.2rem"        
        maxWidth={{xs: "100%", xm: "48rem", lg: "63rem"}}
        mx="auto"
        textAlign="center"
      >
        {description}
      </Text>
      <Grid
        mt="4rem"
        gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr"}}
        gridGap="2rem"        
      >
        {CARDS.map((card, index) => (
          <ShowcaseCard
            key={index}
            {...card}
          />
        ))}
      </Grid>
    </Box>
  )
}