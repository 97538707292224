import { config } from "config";
import Axios from "./axios";

export const isMobile = () => (typeof window !== "undefined" ? window.innerWidth <= 600 : false);
export const isTab = () => (typeof window !== "undefined" ? window.innerWidth <= 1024 : false);

export const formatDate = (date) => {
  let formattedDate;
  if(typeof date === "object"){
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thusday", "Friday", "Saturday"]
    formattedDate = `${dayNames[date?.toDate().getDay()]}, ${monthNames[date?.toDate().getMonth()]} ${date?.toDate().getDate()}, ${date?.toDate().getFullYear()}`
  }else if(typeof date === "string"){
    formattedDate = date
  }
  return formattedDate
}

export const scrollToTop = (window) =>{
  window.scrollTo({top: 0, left: 0, behavior: "smooth" })
}    

export const printINR= (cost) => {
  return new Intl.NumberFormat("en-IN", {style: "currency", currency: "INR", maximumSignificantDigits: 3}).format(cost)
}

export const ISODateToHTMLDate = (date) => {
  const newDate = new Date(date)
  return newDate.toISOString().split("T")[0]
}

export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const { data } = await Axios.post("/uploads", formData);
  return data?.data;
} 

export const generatePublicUrl = (fileName) => {
  return `${config.MEDIA_URL}/${fileName}`;
};