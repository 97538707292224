import { Box, Image, Text } from "atoms";

export const ServiceCard = ({
  image,
  title,
  description,
}) => {
  return (
    <Box
      bg="white"
      boxShadow="0px 10px 25px rgba(0, 0, 51, 0.08)"
      borderRadius="2rem"
      mx="auto"
      width={{xs: "32rem", xm: "36rem"}}      
    >
      <Box
        width="100%"
        height={{xs: "21rem", xm: "24rem"}}        
      >
        <Image
          src={image}
          alt={title}
          width="100%"
          height="100%"
          objectFit="cover"
          borderRadius="2rem 2rem 0 0"          
        />
      </Box>
      <Box
        p="2rem"        
        borderRadius="0 0 2rem 2rem"
      >
        <Text
          fontSize="2rem"
          lineHeight="3rem"
          fontWeight="700"
          color="dark.500"
        >
          {title}
        </Text>
        <Text
          fontSize="1.3rem"
          lineHeight="1.8rem"
          fontWeight="300"
          color="grey.300"
          mt="0.5rem"
        >
          {description}
        </Text>
      </Box>
    </Box>
  )
}