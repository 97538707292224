import { Box, Flex, Image } from "atoms"

export const BrandCard = ({
  image,
  name  
}) => {
  return (
    <Flex
      width="22rem"
      height="14rem"
      alignItems="center"
      justifyContent="center"
      bg="grey.10"      
      border="1px solid"
      borderColor="accent.50"
    >
      <Box
        width="11rem"
        height="10rem"
      >
        <Image
          src={image}
          alt={name}
          width="100%"
          height="100%"
          objectFit="contain"
        />
      </Box>
    </Flex>
  )
}