import { BsPatchCheckFill } from "react-icons/bs";
import { Link } from "react-router-dom";

import { Box, Button, Flex, Image, Text } from "atoms";

export const HomeService = ({
  title,  
  description,
  list,
  image,
  imageAlt,
  btnText,
  btnLink,
}) => {
  return (
    <Flex
      alignItems="center"
      justifyContent={{lg: "center"}}
      py={{ xs: "4rem", xm: "6rem"}} 
      flexDirection={{ xs: "column-reverse", xm: "row"}}
      px={{ xs: "4rem", xm: "6rem"}}
      bg="primary.50"
    >
      <Flex
        mt={{ xs: "4rem", xm: "unset" }}
        alignItems="center"
        justifyContent="center"
        minWidth={{ xs: "100%", xm: "48rem", lg: "63rem"}}
        maxWidth={{ xs: "100%", xm: "48rem", lg: "63rem"}}        
      >
        <Box
          minWidth={{ xs: "100%", xm: "42rem", lg: "51rem" }}
          maxWidth={{ xs: "100%", xm: "42rem", lg: "51rem" }}          
          maxHeight={{ xs: "100%", xm: "48rem", lg: "63rem"}}          
        >
          <Box
            position="absolute"
            width="14.5rem"
            height={{xs: "50%", md: "33.7rem"}}   
            overflow="hidden"           
            top={{xs: "0.5rem", md: "2rem"}}             
            right={{xs: "-1rem", md: "0" }}
            zIndex="0"
          >
            <Image             
              src="/images/dots.png" 
              alt="dots"
              objectFit="cover"                                
            />
          </Box>
          <Image             
            src={image}
            alt={imageAlt}
            width="100%"
            height="100%"
            objectFit="cover"                            
            borderRadius="0 40% 40% 40%"
          />
        </Box>
      </Flex>
      <Box
        minWidth={{xs: "100%", xm: "48rem", lg: "63rem"}}
        maxWidth={{xs: "100%", xm: "48rem", lg: "63rem"}}
      >        
        <Text             
          fontSize="4rem"
          fontWeight="700"
          color="dark.500"
          textAlign={{xs: "center", xm: "start" }}
        >
          {title}
        </Text>        
        <Text
          fontSize="1.6rem"
          fontWeight="300"
          color="grey.300"
          mt="1rem"
          lineHeight="2.2rem"
          textAlign={{xs: "center", xm: "start" }}
        >
          {description}
        </Text>
        <Flex
          mt="2rem"
          flexDirection="column"
          style={{ gap: "1rem" }}
        >
          {list?.map((item, index) => (
            <Flex
              key={index}
              alignItems="center"
            >
              <Box
                fontSize="1.6rem"
                color="primary.500"
                mt="0.4rem"
              >
                <BsPatchCheckFill/>
              </Box>
              <Text
                fontSize="1.6rem"
                color="dark.500"
                ml="0.5rem"
                fontWeight="300"
              >
                {item}
              </Text>
            </Flex>
          ))}
        </Flex>
        <Box mt="4rem" />
        <Link
          to={btnLink}
        >
          <Button
            variant="primaryOutline"
            width="fit-content"
            p="1.4rem 2rem"
            borderRadius="2rem"  
            fontSize="1.6rem"
            mx={{ xs: "auto", xm: "unset" }}            
          >
            {btnText}
          </Button>
        </Link>
      </Box>      
    </Flex>
  )
}