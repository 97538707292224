export const points = [
  "One of the unique service providers of A V Solutions and Asset management system in Uttarakhand with valued representation of market leaders like Panasonic ,Polycom, Harman,Samsung, Epson, Crestron.",
  "Having a direct tie up with major services company like Godrej which is having Largest installed base for video conferencing equipment in India, over 12 years.",
  "Core competencies in design, development and installation of innovative AV solutions along with Cloud video conferencing of market leaders Cisco Webex and Lifesize.",
  "AMC and Onsite support services for all audio video equipments.",
  "Assent Monitoring and Management system for all your warehousing and organisation needs.",
  "Distant learning training room solutions for cost effective training and HR interviews operations in remote areas."
];

export const brands = [
  { name: "Optoma", image: "/images/brands/optoma.png" },
  { name: "Panasonic", image: "/images/brands/panasonic.png" },
  { name: "Polycom", image: "/images/brands/polycom.png" },
  { name: "Sony", image: "/images/brands/sony.png" },
  { name: "JBL", image: "/images/brands/jbl.png" },
  { name: "Samsung", image: "/images/brands/samsung.png" },
  { name: "Epson", image: "/images/brands/epson.png" },
  { name: "Crestron", image: "/images/brands/crestron.png" },
  { name: "Newline", image: "/images/brands/newline.png" },
  { name: "Cisco", image: "/images/brands/cisco.png" },
  { name: "Google Cloud", image: "/images/brands/google-cloud.png" },
  { name: "Sennheiser", image: "/images/brands/sennheiser.png" },
  { name: "Digital Ocean", image: "/images/brands/digital-ocean.png" },
  { name: "Godaddy", image: "/images/brands/godaddy.png" },
  { name: "Phillips", image: "/images/brands/phillips.png" },
]