import { useEffect } from "react";

import { Layout } from "organisms";
import SEO from "seo/SEO";
import { ContactPage } from "templates";
import { scrollToTop } from "utils/utilities";

export const Contact = () => {
  
  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <Layout>      
      <SEO
        title="Contact Us | Prathma Global Konnect Pvt Ltd"
        description="Contact Us | Prathma Global Konnect Pvt Ltd"
        keywords="Contact, Audio Video Solutions, AV Services, Web Development, App Development, Software Development, Home Automation, Automation, Crestron, Altona, Haldwani Uttarakhand, India."        
      /> 
      <ContactPage/>
    </Layout>
  );
};

export default Contact;
