import { useSelector } from "react-redux";

import { Box, Loader } from "atoms";
import { HomeHero, HomeService, HomeShowcase, HomeTestimonial } from "./molecules";

export const HomePage = () => { 
  
  const { home, loading } = useSelector(state => state.app);
  
  return (
    <Box>     
      {loading && <Loader />}   
      <HomeHero
        title="KONNECT"
        subTitle="Automation & Music Destination, We Will Take Care"
        description="PGK Pvt Ltd an integrated technology solutions company focussing on enterprise communication and Information technology domains, is striving and positioning as an ideal partner for organizations looking at transformational business solutions. Our acknowledged capability to optimize its core capabilities across diverse technology platforms, fine human resource compliances and commitment to quality and support facilitates the delivery of best in class solutions resulting in complete customer delight."
        image="/images/home-hero.jpg"
        imageAlt="Home Hero"
        btnText="Contact Us"
        btnLink="/contact-us"
      />   
      <HomeService
        title="We offer the best services"
        description="Our Services includes Audio Video Solutions, Product Development, Digital Marketing, Automation, Music, and more"        
        list={[          
          "Audio Video Solutions",
          "Software Development",
          "Smart Home Solutions",
          "Smart Classroom Solutions",
          "CCTV Solutions",
          "Conference Room Solutions",
          "Auditorium Solutions",
        ]}
        image="/images/home-service.jpg"
        imageAlt="Home Services"
        btnText="View More"
        btnLink="/services"
      />    
      <HomeShowcase 
        title="The Perfect Solutions for Your Business"
        description="We are a team of professionals who are passionate about technology and its applications and constanty work on providing the best possible solutions as per your need"
      />
      <HomeTestimonial
        title="What Our Clients Say"
        reviews={home?.reviews}        
      />
    </Box>
  );
};