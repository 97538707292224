import { Box, Flex, Text } from "atoms";
import { BrandCard } from "molecules";
import { brands, points } from "./data";

export const AboutPage = () => {
  return (
    <Box>
      <Box      
        py={{ xs: "4rem", xm: "6rem"}}       
        px={{ xs: "4rem", xm: "6rem"}}            
      >      
        <Box
          position="absolute"
          width="14.5rem"
          height="20.5rem"
          overflow="hidden"
          top="1rem"
          right="0"
          zIndex="0"
          backgroundImage="url('/images/dots.png')"
        />
        <Box
          position="absolute"
          width="14.5rem"
          height="20.5rem"
          overflow="hidden"
          bottom="0"
          left="0"
          zIndex="0"
          backgroundImage="url('/images/dots.png')"
        />
        <Text             
          fontSize="4rem"
          fontWeight="700"
          color="dark.500"        
          maxWidth={{xs: "100%", xm: "48rem", lg: "63rem"}}
          mx="auto"
          textAlign="center"
        >
          About Us
        </Text>
        <Text
          fontSize="1.6rem" 
          lineHeight="2.4rem"
          fontWeight="400"
          color="grey.500"
          mt="4rem"
          textAlign="center"
          maxWidth="72rem"
          mx="auto"
        >
          PGK Pvt Ltd an integrated technology solutions company focussing on enterprise communication and Information technology domains, is striving and positioning as an ideal partner for organizations looking at transformational business solutions. Our acknowledged capability to optimize its core capabilities across diverse technology platforms, fine human resource compliances and commitment to quality and support facilitates the delivery of best in class solutions resulting in complete customer delight.
        </Text> 
        <Text
          fontSize="1.6rem" 
          lineHeight="2.4rem"
          fontWeight="400"
          color="grey.500"
          mt="2rem"
          textAlign="center"
          maxWidth="72rem"
          mx="auto"
        >
          We are a team of experienced and skilled professionals in the field of Audio - Video - Data collaboration, voice solutions and network optimization. We provide an integrated solution to your business communication needs and providing complete identification and data collection system through bar-coding. We are committed to providing you the best value for your requirements. The end result for customers is peace of mind, higher productivity and higher profits.
        </Text>              
      </Box>
      <Box      
        py={{ xs: "4rem", xm: "6rem"}}       
        px={{ xs: "4rem", xm: "6rem"}}            
        bg="primary.50"
      >
        <Box
          position="absolute"
          width="14.5rem"
          height="20.5rem"
          overflow="hidden"
          top="1rem"
          left="0"
          zIndex="0"
          backgroundImage="url('/images/dots.png')"
        />
        <Box
          position="absolute"
          width="14.5rem"
          height="20.5rem"
          overflow="hidden"
          bottom="0"
          right="0"
          zIndex="0"
          backgroundImage="url('/images/dots.png')"
        />
        <Text
          fontSize="3rem"
          fontWeight="700"
          color="success.500"
          textAlign="center"
        >
          PRATHMA GLOBAL KONNECT PVT LTD : CREDENTIALS
        </Text>
        <Box>
          {points.map((point, index) => (
            <Text
              fontSize="1.6rem"
              lineHeight="2.4rem"
              fontWeight="400"
              color="dark.500"
              mt="2rem"
              textAlign="center"
              maxWidth="72rem"
              mx="auto"
              key={index} 
              bg="white"             
              p="2rem"
              borderRadius="2rem"
              boxShadow="0px 10px 25px rgba(0, 0, 51, 0.08)"
            >
              {point}
            </Text>
          ))}
        </Box>
      </Box>
      <Box      
        py={{ xs: "4rem", xm: "6rem"}}       
        px={{ xs: "4rem", xm: "6rem"}}            
      >      
        <Box
          position="absolute"
          width="14.5rem"
          height="20.5rem"
          overflow="hidden"
          top="1rem"
          right="0"
          zIndex="0"
          backgroundImage="url('/images/dots.png')"
        />
        <Box
          position="absolute"
          width="14.5rem"
          height="20.5rem"
          overflow="hidden"
          bottom="0"
          left="0"
          zIndex="0"
          backgroundImage="url('/images/dots.png')"
        />
        <Text             
          fontSize="4rem"
          fontWeight="700"
          color="dark.500"        
          maxWidth={{xs: "100%", xm: "48rem", lg: "63rem"}}
          mx="auto"
          textAlign="center"
        >
          Brands We Work With
        </Text>
        <Text
          fontSize="1.6rem" 
          lineHeight="2.4rem"
          fontWeight="400"
          color="grey.500"
          mt="4rem"
          textAlign="center"
          maxWidth="72rem"
          mx="auto"
        >
          Trusted by the world's leading brands.
        </Text>  
        <Flex
          mt="4rem"
          justifyContent="center"
          flexWrap="wrap"
          style={{
            gap: "2rem"
          }}
        >
          {brands?.map((brand, index) => (
            <BrandCard
              key={index}
              {...brand}
            />
          ))}
        </Flex>                   
      </Box>
    </Box>
  )
}