import { Box, Flex, Text } from "atoms";

export const ShowcaseCard = ({
  Icon,
  title,
  description,
}) => {
  return (
    <Box
      p="4rem 3rem"
      bg="white"
      boxShadow="0px 10px 25px rgba(0, 0, 51, 0.08)"
      borderRadius="2rem"
      mx="auto"
    >
      <Flex
        width="6rem"
        height="6rem"
        borderRadius="0.4rem"
        bg="primary.100"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          fontSize="3rem"
          color="white" 
          mt="0.4rem"         
        >
          {Icon}        
        </Box>
      </Flex>
      <Text
        mt="2rem"
        fontSize="2rem"
        color="dark.500"
        lineHeight="3rem"
        fontWeight="700"
      >
        {title}
      </Text>
      <Text
        mt="2rem"
        fontSize="1.3rem"
        color="grey.300"
        lineHeight="2.6rem"
        fontWeight="300"
      >
        {description}
      </Text>
    </Box>
  )
}