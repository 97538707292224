import { useEffect } from "react";

import { Layout } from "organisms";
import SEO from "seo/SEO";
import { TestimonialPage } from "templates";
import { scrollToTop } from "utils/utilities";

export const Testimonial = () => {
  
  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <Layout>      
      <SEO
        title="Testimonials | Prathma Global Konnect Pvt Ltd"
        description="Testimonials | Prathma Global Konnect Pvt Ltd"
        keywords="Testimonial, Audio Video Solutions, AV Services, Web Development, App Development, Software Development, Home Automation, Automation, Crestron, Altona, Haldwani Uttarakhand, India."        
      /> 
      <TestimonialPage/>
    </Layout>
  );
};

export default Testimonial;
