import { appConstants } from "redux/constants";

const initState = {      
  error: null,    
  loading: false, 
  message: null,
  home: null,
  reviews: [],
  totalReviews: 0,
  reviewPerPage: 0  
}

export const appReducer = (state = initState, action) => {
  switch(action.type){     
    case appConstants.GET_HOME_DATA_REQUEST:   
    case appConstants.GET_TESTIMONIALS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case appConstants.GET_HOME_DATA_SUCCESS:
      return {
        ...state,
        home: action.payload,
        loading: false,
      }

    case appConstants.GET_TESTIMONIALS_SUCCESS:
      return {
        ...state,
        reviews: action.payload.data,
        totalReviews: action.payload.totalReviews,
        reviewPerPage: action.payload.reviewPerPage,
        loading: false
      }

    case appConstants.GET_HOME_DATA_FAILURE:
    case appConstants.GET_TESTIMONIALS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default: return state;
  }  
}