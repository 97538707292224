import React, { useEffect, useState } from "react";
import { RxDoubleArrowUp } from "react-icons/rx";

import { Box } from "atoms";
import { Footer, Header } from "organisms";
import { scrollToTop } from "utils/utilities";

export const Layout = ({children}) => {

  const [scroll, setScroll] = useState(false);  

  const handleScroll = () => {
    if (window.scrollY >= 200) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <>        
      <Header/>
      <Box as="main">
        {children}
      </Box>   
      <Footer/>
      {scroll && (  <Box
        zIndex="9"
        position="fixed"
        color="primary.100"
        border="0.1rem solid"
        borderColor="primary.100"
        bottom="4rem"
        right="2rem"
        width="3rem"
        height="3rem"
        borderRadius="0.2rem"
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        _hover={{
          color: "white",
          bg: "primary.100",
        }}                
        onClick={() => scrollToTop(window)}
      >
        <RxDoubleArrowUp size="2rem" />
      </Box>)}   
    </>
  );
};
