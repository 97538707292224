import { Box, Grid, Text } from "atoms";
import { ReviewCard } from "molecules";

export const HomeTestimonial = ({
  title,
  reviews  
}) => {
  return (
    <Box      
      py={{ xs: "4rem", xm: "6rem"}}       
      px={{ xs: "4rem", xm: "6rem"}}      
      bg="primary.50"
    >
      <Box
        position="absolute"
        width="14.5rem"
        height="20.5rem"
        overflow="hidden"
        top="1rem"
        right="0"
        zIndex="0"
        backgroundImage="url('/images/dots.png')"
      />
      <Box
        position="absolute"
        width="14.5rem"
        height="20.5rem"
        overflow="hidden"
        bottom="0"
        left="0"
        zIndex="0"
        backgroundImage="url('/images/dots.png')"
      />
      <Text             
        fontSize="4rem"
        fontWeight="700"
        color="dark.500"        
        maxWidth={{xs: "100%", xm: "48rem", lg: "63rem"}}
        mx="auto"
        textAlign="center"
      >
        {title}
      </Text>        
      <Grid
        mt="4rem"
        gridTemplateColumns={{ xs: "1fr", xm: "1fr 1fr", lg: "1fr 1fr 1fr"}}
        gridGap="2rem"        
      >
        {reviews?.map((card, index) => (
          <ReviewCard
            key={index}
            {...card}
          />
        ))}
      </Grid>
    </Box>
  )
}