import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Grid, Loader, Text } from "atoms";
import { ReviewCard } from "molecules";
import { getTestimonials } from "redux/actions";

export const TestimonialPage = () => {

  const dispatch = useDispatch();
  const { loading, reviews } = useSelector(state => state.app);

  useEffect(() => {
    dispatch(getTestimonials(1));
  }, [dispatch])

  return (
    <>
      {loading && <Loader />}  
      <Box      
        py={{ xs: "4rem", xm: "6rem"}}       
        px={{ xs: "4rem", xm: "6rem"}}            
      >    
        <Box
          position="absolute"
          width="14.5rem"
          height="20.5rem"
          overflow="hidden"
          top="1rem"
          right="0"
          zIndex="0"
          backgroundImage="url('/images/dots.png')"
        />
        <Box
          position="absolute"
          width="14.5rem"
          height="20.5rem"
          overflow="hidden"
          bottom="0"
          left="0"
          zIndex="0"
          backgroundImage="url('/images/dots.png')"
        />
        <Text             
          fontSize="4rem"
          fontWeight="700"
          color="dark.500"        
          maxWidth={{xs: "100%", xm: "48rem", lg: "63rem"}}
          mx="auto"
          textAlign="center"
        >
          Testimonials
        </Text>
        <Text 
          fontSize="1.6rem"
          fontWeight="300"
          color="grey.300"
          mt="1rem"
          lineHeight="2.2rem"
          textAlign="center"
        >
          What our customers say about us
        </Text> 
        <Grid
          gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr"}}
          gridGap="2rem"
          mt="4rem"
          // placeItems="center"
        >
          {reviews?.map((review, index) => (
            <ReviewCard
              key={index}
              {...review}
            />
          ))}
        </Grid>       
      </Box>
    </>
  )
}