import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import "react-toastify/dist/ReactToastify.css";

import store from "redux/store";
import { AppRoutes } from "routes";
import { theme } from "styles";

import "styles/globals.css";
import "styles/style.css";

function App() {    
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>  
        <ToastContainer         
          style={{ fontSize: "1.4rem" }}  
          position="top-right"      
        />            
        <Router>
          <AppRoutes />
        </Router>           
      </ThemeProvider>
    </Provider>
  );
}

export default App;
