export const appConstants = { 
  UPDATE_VISITS_REQUEST: "UPDATE_VISITS_REQUEST",
  UPDATE_VISITS_SUCCESS: "UPDATE_VISITS_SUCCESS",
  UPDATE_VISITS_FAILURE: "UPDATE_VISITS_FAILURE",
  GET_HOME_DATA_REQUEST: "GET_HOME_DATA_REQUEST",
  GET_HOME_DATA_SUCCESS: "GET_HOME_DATA_SUCCESS",
  GET_HOME_DATA_FAILURE: "GET_HOME_DATA_FAILURE",
  GET_TESTIMONIALS_REQUEST: "GET_TESTIMONIALS_REQUEST",
  GET_TESTIMONIALS_SUCCESS: "GET_TESTIMONIALS_SUCCESS",
  GET_TESTIMONIALS_FAILURE: "GET_TESTIMONIALS_FAILURE",
};