import { BsLinkedin } from "react-icons/bs";
import { FaFacebookSquare } from "react-icons/fa";
import { SiGmail } from "react-icons/si";

import { Box, Flex, Image, Text } from "atoms";
import { Link } from "react-router-dom";

const LINKS = {
  "left": [
    {
      "id": 1,
      "name": "Home",
      "link": "/"
    },
    {
      "id": 2,
      "name": "About Us",
      "link": "/about-us"
    },
    {
      "id": 3,
      "name": "Services",
      "link": "/services"
    },
  ],
  "right": [
    {
      "id": 4,
      "name": "Testimonials",
      "link": "/testimonials"
    },
    {
      "id": 5,
      "name": "Contact Us",
      "link": "/contact-us"
    },
    {
      "id": 6,
    "name": "Privacy Policy",
      "link": "/privacy-policy"
    }
  ],
  "social": [
    {
      id: 1,
      Icon: <BsLinkedin />,
      link: "https://www.linkedin.com/in/govind-latwal-55a86a29",    
    },
    {
      id: 2,
      Icon: <SiGmail />,
      link: "mailto:sales@pgkonnect.com",    
    },
    {
      id: 3,
      Icon: <FaFacebookSquare />,
      link: "https://www.facebook.com/Konnect-Automation-Music-Destination-1000789720128575/",
    },  
  ]
}

export const Footer = () => {
  return (
    <Box
      as="footer"
      py={{ xs: "4rem", xm: "6rem"}}       
      px={{ xs: "4rem", xm: "6rem"}}      
      bg="dark.100"
      color="grey.100"
    >
      <Flex
        justifyContent="space-between"        
        flexDirection={{ xs: "column", xm: "row" }}
      >
        <Box
          maxWidth={{xs: "36rem", xm: "unset"}}          
          mx={{xs: "auto", xm: "unset" }}
          flex="1"
        >
          <Box
            width="10rem"  
            mx={{xs: "auto", xm: "unset" }}
          >
            <Image
              src="/images/logo-dark.png"
              alt="PGKPL"
              width="100%"
              height="100%"
              objectFit="contain"              
            />
          </Box>
          <Text
            textAlign={{xs: "center", xm: "left" }}
            maxWidth="36rem"
          >
            Prathma Global Konnect Pvt Ltd,
            Bhagwati Tower, Kaladhungi Road
            Unchapul, Haldwani 263139
            Uttarakhand,IN
          </Text>
        </Box>
        <Flex
          justifyContent="space-between" 
          mt={{xs: "2rem", xm: "4rem"}}                   
          flex="1"          
        >
          <Flex
            flexDirection="column"
            style={{ gap: "1rem" }}
          >
            {LINKS.left?.map((link) => (
              <Link
                key={link.id}
                to={link.link}                
              >
                <Text
                  fontSize="1.6rem"
                  lineHeight="2.4rem"
                  fontWeight="400"
                  _hover={{
                    color: "primary.100",
                  }}
                >
                  {link.name}
                </Text>
              </Link>
            ))}
          </Flex>
          <Flex
            flexDirection="column"
            style={{ gap: "1rem" }}
          >
            {LINKS.right?.map((link) => (
              <Link
                key={link.id}
                to={link.link}                
              >
                <Text
                  fontSize="1.6rem"
                  lineHeight="2.4rem"
                  fontWeight="400"
                  _hover={{
                    color: "primary.100",
                  }}
                >
                  {link.name}
                </Text>
              </Link>
            ))}
          </Flex>
          <Box>
            <Text>
              Connect with us
            </Text>
            <Flex
              mt="1.5rem"
              style={{ gap: "2rem" }}
            >
              {LINKS.social?.map((link) => (
                <a
                  key={link.id}
                  href={link.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Box
                    fontSize="2rem"
                    _hover={{
                      color: "primary.100",                      
                    }}
                    transition="all 0.3s ease"
                  >
                    {link.Icon}
                  </Box>
                </a>
              ))}
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <Text
        textAlign="center"
        mt="4rem"
        fontSize="1.4rem"
        lineHeight="2.4rem"
        fontWeight="400"
      >
        © 2023 Prathma Global Konnect Pvt Ltd.        
      </Text>
      <Text
        fontSize="1.4rem"
        lineHeight="2.4rem"
        fontWeight="400"
        textAlign="center"
      >
        Developed by{" "}
        <a 
          href="https://www.linkedin.com/in/karan-karakoti"
          target="_blank"
          rel="noreferrer"
        >
          <Text
            as="span"
            color="primary.100"
            fontSize="1.4rem"
            lineHeight="2.4rem"
            fontWeight="400"
          >
            @karankarakoti
          </Text>
        </a>
      </Text>
    </Box>
  )
}