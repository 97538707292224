export const services = [
  {
    title: "Home Automation",
    description: "A home automation system will control lighting, climate, entertainment systems, and appliances.",
    image: "/images/services/home-automation.jpg",
  },
  {
    title: "Web Development",
    description: "We provide website/web application development services using the fastest technologies.",
    image: "/images/services/web-development.jpg",
  },
  {
    title: "Conference Room",
    description: "The purpose of confrence room is to bring people together in an area that is designed to promote teamwork.",
    image: "/images/services/conference-room.jpg",
  },
  {
    title: "Software Development",
    description: "We provide on demand software development services and various management system softwares.",
    image: "/images/services/software-development.jpg",
  },
  {
    title: "Smart Classroom",
    description: "The classroom provides a space where learning can take place uninterrupted by outside distractions.",
    image: "/images/services/smart-classroom.jpg",
  },
  {
    title: "Video Conferencing System",
    description: "It means to conduct a conference b/w two or more participants at different sites to transmit audio and video data.",
    image: "/images/services/video-conferencing-system.jpg",
  },
  {
    title: "CCTV Solutions",
    description: "It is the use of video cameras to transmit a signal to a specific place, on a limited set of monitors.",
    image: "/images/services/cctv-solutions.jpg",
  },
  {
    title: "Auditorium",
    description: "Auditorium is a large building or hall used for public gatherings, typically speeches or stage performances.",
    image: "/images/services/auditorium.jpg",
  }
]