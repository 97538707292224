import { useEffect, useState } from "react";
import { BsLinkedin } from "react-icons/bs";
import { FaFacebookSquare } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineClose } from "react-icons/md";
import { RiMenu3Fill } from "react-icons/ri";
import { SiGmail } from "react-icons/si";

import { Box, Flex, Image, Text } from "atoms";
import { NavLink } from "react-router-dom";

const socialLinks = [
  {
    id: 1,
    Icon: <BsLinkedin />,
    link: "https://www.linkedin.com/in/govind-latwal-55a86a29",    
  },
  {
    id: 2,
    Icon: <SiGmail />,
    link: "mailto:sales@pgkonnect.com",    
  },
  {
    id: 3,
    Icon: <FaFacebookSquare />,
    link: "https://www.facebook.com/Konnect-Automation-Music-Destination-1000789720128575/",
  },  
]

const nav = [
  {
    id: 1,
    name: "Home",
    link: "/",    
  },
  {
    id: 2,
    name: "About Us",
    link: "/about-us",    
  },
  {
    id: 3,
    name: "Services",
    link: "/services",    
  },
  {
    id: 4,
    name: "Testimonials",
    link: "/testimonials",
  },
  {
    id: 5,
    name: "Contact Us",
    link: "/contact-us",
  }
]

export const Header = () => {

  const [scroll, setScroll] = useState();
  const [open, setOpen] = useState(false);  

  const handleScroll = () => {
    if (window.scrollY >= 120) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <Box 
      as="header"      
      zIndex="100"
    >
      <Box
        display={{ xs: "none", md: "block" }}
        position={scroll ? "fixed" : "unset"}
        top={scroll ? "0" : "unset"}
        left={scroll ? "0" : "unset"}
        width="100vw"
        transition="all 0.3s ease-in-out"
        bg="white"
        boxShadow={scroll ? "0 0 0.5rem rgba(0,0,0,0.2)" : "unset"}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"          
          p="1rem 2rem"
          borderBottom="1px solid"
          borderColor="grey.50"
        >
          <Flex
            alignItems="center"
            style={{ gap: "0.8rem"}}
          >
            <Box
              fontSize="2.8rem"
              mt="0.7rem"
            >
              <FiPhoneCall/>
            </Box>
            <Box>
              <Text fontSize="1.2rem">
                Do you need help?
              </Text>
              <Text 
                fontSize="1.6rem"
                _hover={{
                  color: "primary.500",
                  transition: "color 0.3s ease-in"
                }}                
              >
                <a
                  href="tel:+91-9769599551"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  +91-9769599551
                </a>
              </Text>
            </Box>
          </Flex>
          <Box
            width="3.6rem"
            height="3.6rem"
          >
            <Image
              src="/favicon-main.png"
              alt="PGKPL"
              layout="fill"
              objectFit="contain"              
            />
          </Box>  
          <Flex
            style={{ gap: "1.5rem"}}
          >
            {socialLinks.map(({ id, Icon, link }) => (              
              <a
                key={id}
                href={link}
                target={"_blank"}
                rel="noreferrer"
              >
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  fontSize="2rem"
                  _hover={{
                    color: "primary.500",
                    transition: "color 0.3s ease-in-out"
                  }}
                >
                  {Icon}
                </Flex>
              </a>
            ))}
          </Flex>       
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          style={{ gap: "2rem" }}
          p="1rem 2rem"
          borderBottom="1px solid"
          borderColor="grey.50"
          as="nav"
        >
          {nav.map(({ id, name, link }) => (
            <NavLink
              key={id}
              to={link}
              activeClassName="active"
            >
              <Text
                fontSize="1.6rem"
                fontWeight="400"
                _hover={{
                  color: "primary.500",
                  transition: "color 0.3s ease-in-out"
                }}
              >
                {name}
              </Text>
            </NavLink>
          ))}
        </Flex>
      </Box>
      <Box
        display={{ xs: "block", md: "none" }}
        position={scroll ? "fixed" : "unset"}
        top={scroll ? "0" : "unset"}
        left={scroll ? "0" : "unset"}
        width="100vw"
        transition="all 0.3s ease-in-out"
        bg="white"
        boxShadow={scroll ? "0 0 0.5rem rgba(0,0,0,0.2)" : "unset"}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          p="1rem 2rem"
          borderBottom="1px solid"
          borderColor="grey.50"
        >
          <Box
            width="3.6rem"
            height="3.6rem"
          >
            <Image
              src="/favicon-main.png"
              alt="PGKPL"
              layout="fill"
              objectFit="contain"              
            />
          </Box> 
          <Box
            fontSize="2.4rem"
            cursor="pointer"            
            onClick={() => setOpen(!open)}
            color={open ? "danger.100" : "grey.500"}
          >
            {open ? <MdOutlineClose/> : <RiMenu3Fill/> }
          </Box> 
        </Flex>
        <Box
          display={open ? "block" : "none"}
          p="2rem 2rem"
          borderBottom="1px solid"
          borderColor="grey.50" 
          as="nav"                             
        >
          <Flex
            flexDirection="column"
            style={{ gap: "2rem" }}            
          >
            {nav.map(({ id, name, link }) => (
              <NavLink
                key={id}
                to={link}
                activeClassName="active"
              >
                <Text>
                  {name}
                </Text>
              </NavLink>
            ))}
          </Flex>
          <Flex
            style={{ gap: "1.5rem"}}
            mt="2rem"
          >
            {socialLinks.map(({ id, Icon, link }) => (              
              <a
                key={id}
                href={link}
                target={"_blank"}
                rel="noreferrer"
              >
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  fontSize="2rem"
                  _hover={{
                    color: "primary.500",
                    transition: "color 0.3s ease-in-out"
                  }}
                >
                  {Icon}
                </Flex>
              </a>
            ))}
          </Flex>       
        </Box>
      </Box>
    </Box>
  );
};