import { Box, Grid, Text } from "atoms";
import { ServiceCard } from "molecules";
import { services } from "./data";

export const ServicePage = () => {
  return (
    <Box      
      py={{ xs: "4rem", xm: "6rem"}}       
      px={{ xs: "4rem", xm: "6rem"}}            
    >      
      <Box
        position="absolute"
        width="14.5rem"
        height="20.5rem"
        overflow="hidden"
        top="1rem"
        right="0"
        zIndex="0"
        backgroundImage="url('/images/dots.png')"
      />
      <Box
        position="absolute"
        width="14.5rem"
        height="20.5rem"
        overflow="hidden"
        bottom="0"
        left="0"
        zIndex="0"
        backgroundImage="url('/images/dots.png')"
      />
      <Text             
        fontSize="4rem"
        fontWeight="700"
        color="dark.500"        
        maxWidth={{xs: "100%", xm: "48rem", lg: "63rem"}}
        mx="auto"
        textAlign="center"
      >
        Our Services
      </Text>
      <Text 
        fontSize="1.6rem"
        fontWeight="300"
        color="grey.300"
        mt="1rem"
        lineHeight="2.2rem"
        textAlign="center"
      >
        We provide the best services in the industry using the latest brands and technologies available.
      </Text>
      <Grid
        mt="6rem"
        mb="3rem"
        gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr" }}
        gridGap="2rem"        
      > 
        {services?.map((service, index) => (
          <ServiceCard
            key={index}
            {...service}
          />
        ))}       
      </Grid>
    </Box>
  )
}