import { useEffect } from "react";

import { Layout } from "organisms";
import SEO from "seo/SEO";
import { ServicePage } from "templates";
import { scrollToTop } from "utils/utilities";

export const Service = () => {
  
  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <Layout>      
      <SEO
        title="Our Services | Prathma Global Konnect Pvt Ltd"
        description="Our Services | Prathma Global Konnect Pvt Ltd"
        keywords="Our Services, Audio Video Solutions, AV Services, Web Development, App Development, Software Development, Home Automation, Automation, Crestron, Altona, Haldwani Uttarakhand, India."        
      /> 
      <ServicePage/>
    </Layout>
  );
};

export default Service;
