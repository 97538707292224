import { AiOutlineMail } from "react-icons/ai";
import { BsFillPhoneFill } from "react-icons/bs";

import { Box, Flex, Grid, Text } from "atoms";
import { ContactForm } from "molecules";
import { FaMapMarkedAlt } from "react-icons/fa";

export const ContactPage = () => {
  return (
    <Box>
      <Box      
        py={{ xs: "4rem", xm: "6rem"}}       
        px={{ xs: "4rem", xm: "6rem"}}            
      >      
        <Box
          position="absolute"
          width="14.5rem"
          height="20.5rem"
          overflow="hidden"
          top="1rem"
          right="0"
          zIndex="0"
          backgroundImage="url('/images/dots.png')"
        />
        <Box
          position="absolute"
          width="14.5rem"
          height="20.5rem"
          overflow="hidden"
          bottom="0"
          left="0"
          zIndex="0"
          backgroundImage="url('/images/dots.png')"
        />
        <Text             
          fontSize="4rem"
          fontWeight="700"
          color="dark.500"        
          maxWidth={{xs: "100%", xm: "48rem", lg: "63rem"}}
          mx="auto"
          textAlign="center"
        >
          Contact Us
        </Text>
        <Text 
          fontSize="1.6rem"
          fontWeight="300"
          color="grey.300"
          mt="1rem"
          lineHeight="2.2rem"
          textAlign="center"
        >
          Having a query? Feel free to Contact Us
        </Text>
        <ContactForm/>
      </Box>
      <Box
        py={{ xs: "4rem", xm: "6rem"}}       
        px={{ xs: "4rem", xm: "6rem"}}            
        bg="primary.50"
      >
        <Grid
          gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr"}}
          gridGap="2rem"          
        >
          <Box
            boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
            p={{xs: "4rem", md: "4rem 8rem"}}
            borderRadius="2rem"
            bg="white"
          >          
            <Text
              fontSize="2.4rem"
              lineHeight="3.2rem"
              fontWeight="700"
              color="dark.500"
              textAlign="center"
            >
              Get in Touch
            </Text>
            <Box
              as="a" 
              href="tel:+91-9769599551"                          
              target="_blank"
              rel="noreferrer"
              display="flex" 
              alignItems="center"                                 
              fontSize={{xs: "1.5rem", md: "1.8rem"}}
              mt="2.5rem"                
            >
              <Flex
                mr="0.6rem"
                alignItems="center"
                justifyContent="center"
                color="primary.500"
              >
                <BsFillPhoneFill/>
              </Flex>
              <Text
                color="grey.300"
                _hover={{
                  color: "primary.500"
                }}
              >
                +91-9769599551
              </Text>
            </Box>
            <Box
              as="a" 
              href="mailto:sales@pgkonnect.com"                          
              target="_blank"
              rel="noreferrer"
              display="flex" 
              alignItems="center"                                 
              fontSize={{xs: "1.5rem", md: "1.8rem"}}
              mt="2.5rem"                
            >
              <Flex
                mr="0.6rem"
                alignItems="center"
                justifyContent="center"
                color="primary.500"
              >
                <AiOutlineMail/>
              </Flex>
              <Text
                color="grey.300"
                _hover={{
                  color: "primary.500"
                }}
              >
                sales@pgkonnect.com
              </Text>
            </Box>
          </Box>

          <Box
            boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
            p={{xs: "4rem", md: "4rem 8rem"}}
            borderRadius="2rem"
            bg="white"
          >          
            <Text
              fontSize="2.4rem"
              lineHeight="3.2rem"
              fontWeight="700"
              color="dark.500"
              textAlign="center"
            >
              Location
            </Text>
            <Box              
              display="flex" 
              // alignItems="center"                                 
              fontSize={{xs: "1.5rem", md: "1.8rem"}}
              mt="2.5rem"                
            >
              <Flex
                mr="0.6rem"                
                justifyContent="center"
                color="primary.500"
              >
                <FaMapMarkedAlt/>
              </Flex>
              <Text
                color="grey.300"
                _hover={{
                  color: "primary.500"
                }}
              >
                Prathma Global Konnect Pvt Ltd, <br/>
                Bhagwati Tower, Kaladhungi Road<br/>
                Unchapul, Haldwani 263139<br/>
                Uttarakhand,INDIA
              </Text>
            </Box>
          </Box>

          <Box
            boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"            
            borderRadius="2rem"
            bg="white"
          >
            <iframe 
              title="Prathma Global Konnect Pvt Ltd, Bhagwati Tower, Kaladhungi Road Unchapul, Haldwani 263139 Uttarakhand,INDIA"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3481.992263265288!2d79.5045654149229!3d29.223782082188343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a09b1adeccbfd9%3A0x66920ddc695b3af6!2sPrathma%20Global%20Konnect%20Private%20Limited!5e0!3m2!1sen!2sin!4v1674991534377!5m2!1sen!2sin" 
              width="100%" 
              height="100%" 
              style={{
                border: "0",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)"            
              }}              
              allowfullscreen="" 
              loading="lazy" 
              referrerpolicy="no-referrer-when-downgrade"  
            />
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}