import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";

import { Loader } from "atoms";
import { checkVisit } from "redux/actions";
import Home from "pages/home";
import NotFound from "pages/not-found";
import Contact from "pages/contact";
import Testimonial from "pages/testimonial";
import About from "pages/about";
import Service from "pages/service";

export const AppRoutes = () => {  

  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);

  useEffect(() => {
    dispatch(checkVisit());    
  }, [dispatch])

  return (
    <Suspense fallback={<Loader />}>
      {loading && <Loader />}
      <Routes>
        <Route path="/" exact element={<Home />} />        
        <Route path="/contact-us" exact element={<Contact />} />        
        <Route path="/testimonials" exact element={<Testimonial />} />        
        <Route path="/about-us" exact element={<About />} />        
        <Route path="/services" exact element={<Service />} />        
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};