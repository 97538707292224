import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { ImQuotesRight } from "react-icons/im";

import { Box, Flex, Image, Text } from "atoms";

export const ReviewCard = ({
  name,
  designation,  
  image,
  comment,
  rating,
}) => {
  return (
    <Box
      boxShadow="0px 10px 25px rgba(0, 0, 51, 0.08)"
      borderRadius="2rem"
      bg="white"
      p="3rem 2rem"
      minWidth="32rem"
      maxWidth={{xs: "36rem", md: "42rem"}}
      mx="auto"
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
      >      
        <Flex
          alignItems="center"
        >
          <Box
            width="5.6rem"
            height="5.6rem" 
            mr="0.8rem"         
          >
            <Image
              src={image}
              alt={name}  
              width="100%"
              height="100%"          
              borderRadius="50%"
              objectFit="cover"
            />
          </Box>
          <Box>
            <Text
              fontSize="1.3rem"
              lineHeight="2rem"
              fontWeight="700"
              color="dark.500"            
            >
              {name}
            </Text>
            <Text
              fontSize="1.3rem"
              lineHeight="2rem"
              fontWeight="400"
              color="grey.400"               
            >
              {designation}
            </Text>
          </Box>
        </Flex>
        <Box
          fontSize="2.6rem"
          lineHeight="3.6rem"
          color="primary.500"
        >
          <ImQuotesRight/>
        </Box>
      </Flex>
      <Flex
        mt="2rem"
        alignItems="center"        
      >
        {Array(rating).fill()?.map((_, index) => (
          <Box
            key={index}
            fontSize="2.6rem"
            color="primary.100"
          >
            <AiFillStar/>
          </Box>
        ))}
        {Array(5-rating).fill()?.map((_, index) => (
          <Box
            key={index}
            fontSize="2.6rem"
            color="primary.100"
          >
            <AiOutlineStar/>
          </Box>
        ))}
      </Flex>
      <Text
        mt="1rem"
        fontSize="1.3rem"
        lineHeight="2.6rem"
        fontWeight="300"
        color="grey.300"
      >
        {comment}
      </Text>
    </Box>
  )
}