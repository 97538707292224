import { v4 as uuidv4 } from "uuid";

import { appConstants } from "redux/constants";
import Axios from "utils/axios";

export const checkVisit = () => {
  return async dispatch => {
    const device = localStorage.getItem("device");
    const _data = {}
    if (!device) {
      localStorage.setItem("device", uuidv4());    
      _data.type = "unique";
    }else{
      _data.type = "total";
    }
    try{
      dispatch({
        type: appConstants.UPDATE_VISITS_REQUEST
      })

      const { data } = await Axios.put("/app/visits", _data)
      dispatch({
        type: appConstants.UPDATE_VISITS_SUCCESS,
        payload: data?.data
      })
    }catch(error){
      dispatch({
        type: appConstants.UPDATE_VISITS_FAILURE,
        payload: error.response.data.error
      })
    }
  }
}

export const getHomeData = () => {
  return async dispatch => {
    try{
      dispatch({
        type: appConstants.GET_HOME_DATA_REQUEST
      })

      const { data } = await Axios.get("/app/initial-data")
      dispatch({
        type: appConstants.GET_HOME_DATA_SUCCESS,
        payload: data?.data
      })
    }catch(error){
      dispatch({
        type: appConstants.GET_HOME_DATA_FAILURE,
        payload: error.response.data.error
      })
    }
  }
}

export const getTestimonials = (page, rating) => {
  return async dispatch => {
    try{
      dispatch({
        type: appConstants.GET_TESTIMONIALS_REQUEST
      })
      let URL = `/reviews?page=${page}`
      if(rating) URL += `&rating=${rating}`
      const { data } = await Axios.get(URL)
      dispatch({
        type: appConstants.GET_TESTIMONIALS_SUCCESS,
        payload: {
          data: data?.data?.reviews,
          totalReviews: data?.data?.totalCount,
          reviewPerPage: data?.data?.resultPerPage
        }
      })
    }catch(error){
      dispatch({
        type: appConstants.GET_TESTIMONIALS_FAILURE,
        payload: error.response.data.error
      })
    }
  }
}