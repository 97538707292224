import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Layout } from "organisms";
import { getHomeData } from "redux/actions";
import SEO from "seo/SEO";
import { HomePage } from "templates";
import { scrollToTop } from "utils/utilities";

export const Home = () => {

  const dispatch = useDispatch();
  
  useEffect(() => {
    scrollToTop(window);
    dispatch(getHomeData());
  }, [dispatch]);

  return (
    <Layout>      
      <SEO
        title="Audio Video Solutions | AV Services | Web/App Development - PGKONNECT"
        description="PGKONNECT offers Audio-Visual Solutions from leading brands worldwide along with Tech Solutions such as Web/App/Software Development, Home Automation, and Automation with Brands like Crestron, Altona, etc. Based in Haldwani Uttarakhand, we provide AV Services all over India and Tech Services Worldwide."
        keywords="Audio Video Solutions, AV Services, Web Development, App Development, Software Development, Home Automation, Automation, Crestron, Altona, Haldwani Uttarakhand, India."        
      /> 
      <HomePage/>
    </Layout>
  );
};

export default Home;
